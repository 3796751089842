import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Tree, Button, Spacer } from "../components";

import { getProjectName } from "../projects";

const ContextDialog = (props) => {
  const [hierarchy, setHierarchy] = useState([]);
  const projectName = getProjectName(props.project);
  const [selectedContext, setSelectedContext] = useState(props.currentContext);
  const [expandedKeys, setExpandedKeys] = useState({
    [projectName]: true,
    [props.currentContext.path]: true,
  });

  useEffect(() => {
    console.log("Loading hierarchy for project ", projectName);
    axios
      .get(`/api/context/${projectName}`)
      .then((response) => {
        if (response.data.response === 200) {
          console.log(response.data.hierarchy)
          setHierarchy(response.data.hierarchy);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error("Unable to load project hierarchy");
      });
  }, [projectName]);

  const onHide = () => {
    props.onHide();
  };

  const onSelect = () => {
    props.onSelect(selectedContext);
  };

  return (
    <Dialog
      visible={true}
      header="Select batch context"
      onHide={onHide}
      style={{ width: "80%", height: "80%" }}
    >
      <div
        style={{
          position: "absolute",
          left: "20px",
          right: "20px",
          bottom: "20px",
          top: "80px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flexGrow: 1, overflowY: "scroll" }}>
          <Tree
            filter={true}
            selectionMode="single"
            expandedKeys={expandedKeys}
            selectionKeys={selectedContext.path}
            onSelect={(e) => {
              setSelectedContext({ ...e.node.data, path: e.node.key });
            }}
            onToggle={(e) => {
              setExpandedKeys(e.value);
            }}
            value={hierarchy}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          {selectedContext.path}
          <Spacer />
          <Button
            onClick={onSelect}
            disabled={!selectedContext.path}
            label="Select"
          />
        </div>
      </div>
    </Dialog>
  );
};

export { ContextDialog };
