import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { Avatar, Button, Menu, Spacer } from "../components";
import { PublishingBatch } from "../containers/PublishingBatch";
import { PublishDialog } from "../containers/PublishDialog";
import { ReportDialog } from "../containers/ReportDialog";

const UploadPage = ({ userData }) => {
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const menuRef = useRef(null);
  const batches = useSelector((state) => ({ ...state.batchesReducer }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (batches.items.length === 0) {
      dispatch({ type: "ADD_BATCH" });
    }
    // eslint-disable-next-line
  }, []);

  const onLogout = () => {
    axios.post("/api/logout").then(() => {
      localStorage.removeItem("access_token");
      window.location.reload();
    });
  }; // logout

  let allowPublish = true;
  for (const batch of batches.items) {
    if (!batch.context) {
      allowPublish = false;
      break;
    }
  }

  const menuModel = [
    {
      label: userData.name,
      items: [
        {
          label: "Status report",
          icon: "pi pi-chart-bar",
          command: () => setShowReportDialog(true),
        },
        {
          label: "Sign out",
          icon: "pi pi-sign-out",
          command: onLogout,
        },
      ],
    },
  ];

  return (
    <main className="upload-page">
      <nav>
        <div class="small-button-wrapper">
          <img
            alt="WebPublisher"
            src="/logo192.png"
            style={{
              width: "32px",
              height: "32px",
            }}
          ></img>
        </div>
        <Spacer />
        <div class="big-button-wrapper">
          <Button
            icon="pi pi-plus"
            label="Add batch"
            className="p-button p-button-success"
            tooltipOptions={{ position: "bottom" }}
            onClick={() => {
              dispatch({ type: "ADD_BATCH" });
              setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight + 1000);
              }, 50);
            }}
          />
        </div>
        <Spacer />

        <div class="small-button-wrapper">
          <Avatar
            image={userData.picture}
            shape="circle"
            onClick={(event) => menuRef.current.toggle(event)}
          />
        </div>

        <Menu model={menuModel} popup ref={menuRef} />
      </nav>

      {batches.items.map((batch) => (
        <PublishingBatch
          onShowReportDialog={setShowReportDialog}
          key={batch.uuid}
          {...batch}
        />
      ))}

      <div class="big-button-wrapper">
        <Button
          icon="pi pi-send"
          label="Publish"
          className="p-button p-button-info"
          onClick={() => setShowPublishDialog(true)}
          tooltipOptions={{ position: "bottom" }}
          disabled={!allowPublish}
        />
      </div>

      {showPublishDialog && (
        <PublishDialog onHide={() => setShowPublishDialog(false)} />
      )}

      {showReportDialog && (
        <ReportDialog
          onSetBatchId={setShowReportDialog}
          batchId={showReportDialog}
          userData={userData}
        />
      )}
    </main>
  );
};

export { UploadPage };
