import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import moment from "moment";
import localization from "moment/locale/en-gb";

import { toast, ToastContainer } from "react-toastify";

import { createStore } from "redux";
import { Provider } from "react-redux";
import { reducer } from "./reducer";
import { setProjectList } from "./projects";

import { ProgressSpinner } from "./components";
import { UploadPage } from "./pages/UploadPage";
import { LoginPage } from "./pages/LoginPage";

import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./index.sass";

moment.updateLocale("en-gb", localization);
const store = createStore(reducer);

const DEFAULT_USER_DATA = {
  name: "",
  email: "",
  picture: "",
  access_token: localStorage.getItem("access_token"),
};

const LoadingOverlay = () => {
  // Full screen loading indicator
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "white",
        zIndex: "1000",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ProgressSpinner />
    </div>
  );
};

const App = () => {
  const [userData, setUserData] = useState(DEFAULT_USER_DATA);
  const [appInfo, setAppInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // appInfo store slack client ID and other information
    // needed right after the app is loaded
    console.log("Loading app info");
    axios
      .get("/api/appinfo")
      .then((res) => {
        if (res.data.response === 200) {
          console.log("App info loaded");
          setAppInfo({
            slack_client_id: res.data.slack_client_id,
          });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error("Error getting app info", err);
      });
  }, []);

  useEffect(() => {
    if (!userData.access_token) {
      setLoading(false);
      return;
    }

    console.log("Loading ctx");
    setLoading(true);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${userData.access_token}`;
    axios
      .get("/api/context")
      .then((res) => {
        console.log("CTX RX", res.data);
        if (res.data.response === 200) {
          setProjectList(res.data.projects);
          setUserData(res.data.user);
        } else {
          toast.error("Failed to load context");
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Error getting user info", err);
        console.log(err);
      });
  }, [userData.access_token]);

  const onLogin = (newUserData) => {
    console.log("ON LOGIN", newUserData);
    localStorage.setItem("access_token", newUserData.access_token);
    setUserData(newUserData);
    window.history.replaceState({}, "", "/");
  };

  if (loading) return <LoadingOverlay />;

  if (userData.email) return <UploadPage userData={userData} />;

  return (
    <LoginPage
      clientID={appInfo.slack_client_id}
      onLogin={onLogin}
      setLoading={setLoading}
    />
  );
};

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
      <ToastContainer position="bottom-right" pauseOnFocusLoss={false} />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
