import { useDispatch } from "react-redux";
import { Button, Spacer, Message } from "../components";

const PublishingBatchTask = ({ files, name, uuid, disabled }) => {
  const dispatch = useDispatch();

  let label;
  if (name) label = name;
  else if (files.length > 1) label = `Sequence starting with ${files[0].name}`;
  else label = files[0].name;

  if (files.length > 1) label += ` (${files.length} files)`;

  let filesRemaining = files.length;
  for (const file of files) {
    if (file.published) filesRemaining -= 1;
  }

  let statusInfo;
  if (filesRemaining === files.length)
    statusInfo = { text: "Pending", severity: "info" };
  else if (filesRemaining === 0)
    statusInfo = { text: "Uploaded", severity: "success" };
  else
    statusInfo = {
      text: `${filesRemaining} files remaining`,
      severity: "info",
    };

  return (
    <div className="publishing-batch-task">
      <span>{label}</span>

      <Spacer />

      <Message
        style={{
          background: "none",
        }}
        {...statusInfo}
      />

      <Button
        icon="pi pi-times"
        tooltip="Remove task"
        tooltipOptions={{ position: "bottom" }}
        className="p-button-text p-button-danger"
        onClick={() => dispatch({ type: "DELETE_TASK", uuid: uuid })}
        disabled={disabled}
      />
    </div>
  );
};

export { PublishingBatchTask };
