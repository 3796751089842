import { useEffect } from "react";
import { toast } from "react-toastify";
import queryString from "query-string";
import axios from "axios";

import Logo from "../openpype.svg";

const LoginPage = ({ clientID, onLogin, setLoading }) => {
  const queryParams = queryString.parse(window.location.search);
  const redirectUri = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  useEffect(() => {
    if (queryParams.code) {
      console.log("Exchanging tokens");
      setLoading(true);
      axios
        .post("/api/login", {
          code: queryParams.code,
          redirect_uri: redirectUri,
        })
        .then((response) => {
          onLogin(response.data.user);
        })
        .catch((error) => {
          toast.error("Error: " + error.response.data.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  const ouauthRequest = queryString.stringify({
    response_type: "code",
    scope: "openid profile email",
    client_id: clientID,
    redirect_uri: redirectUri,
  });

  return (
    <main className="login-page">
      <div className="login-form">
        <img src={Logo} className="logo" alt="openPYPE" />
        <h1>Web Publisher</h1>

        <a href={`https://slack.com/openid/connect/authorize?${ouauthRequest}`}>
          <img
            style={{
              width: "172px",
              height: "40px",
            }}
            alt=""
            src="https://platform.slack-edge.com/img/sign_in_with_slack@2x.png"
          />
        </a>
      </div>
    </main>
  );
};

export { LoginPage };
