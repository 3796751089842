import { useRef } from "react";

import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Menu } from "primereact/menu";
import { Message } from "primereact/message";
import { Dialog } from "primereact/dialog";
import { Tree } from "primereact/tree";
import { Password as InputPassword } from "primereact/password";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";

const FileSelectWidget = ({
  multiple,
  onSelect,
  label,
  icon,
  disabled,
  extensions,
}) => {
  const inputRef = useRef(null);

  const onChange = (event) => {
    const files = [...event.target.files];
    if (files.length === 0) return;
    onSelect(files);
    inputRef.current.value = null;
  };

  const accept = extensions.map((ext) => `.${ext}`).join(",");

  return (
    <div className="file-select-widget">
      <Button
        label={label}
        icon={icon}
        className="p-button p-button-success"
        onClick={() => {
          inputRef.current.click();
        }}
        disabled={disabled}
      />

      <input
        ref={inputRef}
        accept={accept}
        id="file-upload"
        type="file"
        onChange={onChange}
        multiple={multiple}
      />
    </div>
  );
};

const Spacer = (props) => {
  return <div style={{ flexGrow: 1, ...props.style }}>{props.children}</div>;
};

export {
  Avatar,
  Badge,
  Button,
  Dialog,
  Dropdown,
  InputText,
  InputPassword,
  InputSwitch,
  Menu,
  Message,
  ProgressBar,
  ProgressSpinner,
  Tree,
  FileSelectWidget,
  Spacer,
};
