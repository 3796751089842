import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import axios from "axios";

import { Button, Dialog, ProgressBar } from "../components";
import { unicode2ascii, formatFileSize } from "../common";
import { getProjectName } from "../projects";

const PublishDialog = (props) => {
  const tasks = useSelector((state) => ({ ...state.tasksReducer }));
  const batches = useSelector((state) => ({ ...state.batchesReducer }));
  const dispatch = useDispatch();

  const [publishing, setPublishing] = useState(false);
  const [bytesTotal, setBytesTotal] = useState(0);
  const [filesTotal, setFilesTotal] = useState(0);
  const [bytesTransferred, setBytesTransferred] = useState(0);
  const [filesTransferred, setFilesTransferred] = useState(0);
  const [currentFile, setCurrentFile] = useState("");
  const [currentFileSize, setCurrentFileSize] = useState(0);
  const [currentFileTransferred, setCurrentFileTransferred] = useState(0);
  const [procErrors, setProcErrors] = useState(0);

  // Count how many files and bytes we have to transfer
  // Do not use useMemo() because we don't want to update counts while publishing
  useEffect(() => {
    if (!publishing) {
      var b = 0;
      var f = 0;
      for (const task of tasks.items) {
        for (const file of Array.from(task.files)) {
          if (!file.published) {
            b += file.size;
            f += 1;
          }
        }
      }
      setBytesTotal(b);
      setFilesTotal(f);
    }
  }, [tasks.items, publishing]);

  const onHide = () => {
    if (publishing) {
      //TODO: Cancel the publish
      toast.error("Unable to close publishing dialog during upload");
      return;
    }
    props.onHide();
  };

  // Don't bother with the progress bar if there are no tasks
  if (!filesTotal) {
    return (
      <Dialog
        visible={true}
        header="Publish"
        onHide={props.onHide}
        style={{ width: "50%" }}
      >
        <p>All files have been uploaded.</p>
      </Dialog>
    );
  }

  const abortController = new AbortController();
  const cancelToken = axios.CancelToken;
  const cancelTokenSource = cancelToken.source();

  const onPublish = async () => {
    setPublishing(true);
    setBytesTransferred(0);
    setFilesTransferred(0);
    setProcErrors(0);

    let byteTrack = 0;
    let fileTrack = 0;
    let numBatchErr = 0;
    let aborted = false;

    const onProgress = (event) => {
      if (event.lengthComputable) {
        setBytesTransferred(byteTrack + event.loaded);
        setCurrentFileTransferred(event.loaded);
      }
    };

    for (const batch of batches.items) {
      if (!batch.project) {
        toast.error(`Unable to publish batch. Project is not set`);
        continue;
      }
      // TODO: Additional batch metadata validation

      const batchTasks = tasks.items.filter((t) => t.batch === batch.uuid);

      let atTaskInBatch = 0;

      for (const task of batchTasks) {
        let numTaskErr = 0;

        if (!numTaskErr)
          dispatch({
            type: "SET_TASK_STATE",
            uuid: task.uuid,
            state: "PARTIAL",
          });

        console.log(
          "Tasks in batch remaining",
          batchTasks.length - atTaskInBatch
        );

        // Track how many files of this task are published. This is used to
        // send X-PypeWP-Last-File-In-Task header to the server with the last file.
        // This way, we avoid a need to send additional request after each task.

        const numFilesInTask = Array.from(task.files).filter(
          (file) => !file.published
        ).length;
        let atFileInTask = 0;

        for (const index in Array.from(task.files)) {
          let uploaded = false;
          const file = Array.from(task.files)[index];
          if (file.published) continue;

          setCurrentFile(file.name);
          setCurrentFileSize(file.size);
          setCurrentFileTransferred(0);

          let response = {
            data: { response: 500, message: "something's terribly wrong" },
          };

          try {
            response = await axios.post("/api/upload", file, {
              signal: abortController.signal,
              cancelToken: cancelTokenSource.token,
              onUploadProgress: onProgress,
              headers: {
                "Content-Type": "application/octet-stream",
                "X-PypeWP-Project-ID": batch.project,
                "X-PypeWP-Project-Name": getProjectName(batch.project),
                "X-PypeWP-Context": JSON.stringify(batch.context),
                "X-PypeWP-Variant": unicode2ascii(batch.variant),
                "X-PypeWP-Studio-Processing": batch.studioProcessing,
                "X-PypeWP-Batch": task.batch,
                "X-PypeWP-Task": task.uuid,
                "X-PypeWP-File-Name": unicode2ascii(file.name),
                "X-PypeWP-File-Size": file.size,
                "X-PypeWP-Last-File-In-Task":
                  numFilesInTask - atFileInTask === 1,
                "X-PypeWP-Last-File-In-Batch":
                  batchTasks.length - atTaskInBatch === 1,
              },
            });
          } catch (error) {
            console.log(error);
            numTaskErr += 1;
            if (axios.isCancel(error)) {
              console.log("Request canceled", error.message);
              aborted = true;
              break;
            } else if (error.response) {
              response.data = {
                response: error.response.status,
                message: error.response.statusText,
              };
            }
          }

          if (response.data.response === 201) {
            uploaded = true;
          } else {
            numTaskErr += 1;
            console.log(
              `Failed to upload ${file.name} (server error: ${response.data.message})`
            );
          }

          byteTrack += file.size;
          fileTrack += 1;

          setFilesTransferred(fileTrack);
          setBytesTransferred(byteTrack);
          if (uploaded) {
            atFileInTask += 1;
            dispatch({
              type: "SET_FILE_PUBLISHED",
              uuid: task.uuid,
              index: index,
            });
          }
        } // for each file in task

        if (numTaskErr) {
          numBatchErr += 1;
        } else {
          dispatch({
            type: "SET_TASK_STATE",
            uuid: task.uuid,
            state: "UPLOADED",
          });
          atTaskInBatch += 1;
        }

        if (aborted) break;
      } // for each task

      if (aborted) {
        break;
      }
    } // for each batch

    if (numBatchErr) setProcErrors(numBatchErr);
    else setProcErrors(0);

    setPublishing(false);
  }; // end onPublish

  // const onAbort = () => {
  //     console.log("Aborting")
  //     abortController.abort()
  //     cancelTokenSource.cancel()
  // }

  // Let's build the dialog finally

  const header = `${filesTotal} files about to be published (${formatFileSize(
    bytesTotal
  )}).`;
  const footer = (
    <>
      <Button
        label="Start"
        onClick={onPublish}
        disabled={publishing}
        className="p-button p-button-info"
      />
    </>
  );

  const progressCurrent =
    currentFileSize > 0 ? (currentFileTransferred / currentFileSize) * 100 : 0;
  const progressTotal =
    bytesTotal > 0 ? (bytesTransferred / bytesTotal) * 100 : 0;
  const fileProgress = filesTransferred
    ? `${filesTransferred + 1} of ${filesTotal}`
    : "";

  return (
    <Dialog
      visible={true}
      onHide={onHide}
      header={header}
      footer={footer}
      style={{ width: "50%" }}
    >
      {!publishing &&
        (procErrors ? (
          <p>
            Some of the files ({procErrors}) weren't published. You may review
            the errors and retry the upload by clicking the "Start" button
            again.
          </p>
        ) : (
          <p>Ready for publish. Press start to upload your files.</p>
        ))}

      {publishing && (
        <div className="progress-dialog-form">
          <p>
            Current file: {currentFile || "(none)"} {fileProgress}
          </p>
          <ProgressBar value={progressCurrent} showValue={false} />
          <p>Total:</p>
          <ProgressBar value={progressTotal} showValue={false} />
        </div>
      )}
    </Dialog>
  );
};

export { PublishDialog };
