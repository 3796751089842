const setProjectList = (projects) => {
  localStorage.setItem("projects", JSON.stringify(projects));
};

const getProjectList = () => {
  return localStorage.getItem("projects")
    ? JSON.parse(localStorage.getItem("projects"))
    : [];
};

const setLastProjectID = (id) => {
  localStorage.setItem("lastProjectID", id);
};

const getLastProjectID = () => {
  const projectID = localStorage.getItem("lastProjectID");
  if (projectID) {
    for (const project of getProjectList()) {
      if (project.id === projectID) {
        return projectID;
      }
    }
  }
};

const getProjectName = (id) => {
  for (const project of getProjectList()) {
    if (project.id === id) {
      return project.name;
    }
  }
};

export {
  setProjectList,
  getProjectList,
  setLastProjectID,
  getLastProjectID,
  getProjectName,
};
