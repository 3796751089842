import { useEffect, useMemo, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  Button,
  Spacer,
  Dropdown,
  InputText,
  FileSelectWidget,
  Message,
  InputSwitch,
} from "../components";
import { ContextDialog } from "../containers/ContextDialog";
import { PublishingBatchTask } from "./PublishingBatchTask";

import { parseImageSequence } from "../common";
import {
  getLastProjectID,
  getProjectList,
  setLastProjectID,
  getProjectName,
} from "../projects";

import axios from "axios";

const VALID_STUDIO_PROCESSING_EXTENSIONS = ["tvp", "tvpp", "psd"];
const VALID_SEQUENCE_EXTENSIONS = [
  "jpeg",
  "jpg",
  "png",
  "tga",
  "targa",
  "tiff",
  "tif",
  "exr",
  "dpx",
];
const VALID_SINGLE_FILE_EXTENSIONS = [
  ...VALID_SEQUENCE_EXTENSIONS,
  "mov",
  "mp4",
  "tvp",
  "psd",
  "tvpp",
  "nk",
  "aep",
];

const NoTaskLabel = () => {
  return (
    <div
      style={{
        marginTop: "15px",
        marginBottom: "10px",
        color: "#a0a0a0",
        userSelect: "none",
      }}
    >
      <i>Please add a file or a sequence...</i>
    </div>
  );
};

const PublishingBatch = (props) => {
  const dispatch = useDispatch();
  const tasks = useSelector((state) => ({ ...state.tasksReducer }));
  const [showContextDialog, setShowContextDialog] = useState(false);
  const [batchTasks, setBatchTasks] = useState([]);
  const [locked, setLocked] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [report, setReport] = useState(null);
  const project = props.project;
  const [projectSettings, setProjectSettings] = useState(null);
  const studioProcessing = props.studioProcessing;

  const projects = useMemo(() => {
    return getProjectList();
  }, []);

  useEffect(() => {
    axios
      .get(`/api/projects/${getProjectName(project)}`)
      .then((res) => {
        setProjectSettings(res.data.settings);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [project]);

  const onSelectProject = useCallback(
    (value) => {
      dispatch({ type: "SET_BATCH_PROJECT", uuid: props.uuid, project: value });
      setLastProjectID(value);
    },
    [props.uuid, dispatch]
  );

  useEffect(() => {
    if (!project) onSelectProject(getLastProjectID());
  }, [project, onSelectProject]);

  useEffect(() => {
    let items = tasks.items.filter((task) => task.batch === props.uuid);
    let doSetUploaded = items.length > 0;
    let doSetLock = false;
    for (let task of items) {
      if (task.state !== "PENDING") doSetLock = true;
      doSetUploaded = doSetUploaded && task.state === "UPLOADED";
    }
    setLocked(doSetLock);
    setUploaded(doSetUploaded);
    setBatchTasks(items);
  }, [props.uuid, tasks.items]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (uploaded) {
        axios.get(`/api/report/${props.uuid}/brief`).then((response) => {
          setReport(response.data.report);
        });
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [uploaded, props.uuid]);

  const onDeleteBatch = () => {
    dispatch({ type: "DELETE_TASKS", batch: props.uuid });
    dispatch({ type: "DELETE_BATCH", uuid: props.uuid });
  };

  const onReprocess = () => {
    axios.post(`/api/reprocess/${props.uuid}`).then((response) => {
      toast.info("Reprocessing requested");
    });
  };

  const onSelectFiles = (files) => {
    var name = files[0].name;
    if (files.length > 1) {
      const sequence = parseImageSequence(
        files,
        projectSettings.sequence_exts || VALID_SEQUENCE_EXTENSIONS
      );
      if (!sequence) {
        toast.error("File list is not a valid image sequence");
        return;
      }
      name = sequence;
    } else if (files.length === 1) {
      const extension = name.substr(name.lastIndexOf(".") + 1).toLowerCase();

      if (studioProcessing) {
        if (
          (
            projectSettings.studio_exts || VALID_STUDIO_PROCESSING_EXTENSIONS
          ).indexOf(extension) < 0
        ) {
          toast.error(
            `${extension} is not valid format for PSD farm processing`
          );
          return;
        }
      } else if (
        (projectSettings.file_exts || VALID_SINGLE_FILE_EXTENSIONS).indexOf(
          extension
        ) < 0
      ) {
        toast.error(`${extension} is not valid format for upload`);
        return;
      }
    } else {
      return;
    }
    dispatch({ type: "ADD_TASK", batch: props.uuid, files: files, name: name });
  };

  let batch_status;
  if (!batchTasks.length)
    batch_status = {
      text: "Empty batch",
      severity: "warn",
    };
  else if (!uploaded)
    batch_status = {
      text: "Not uploaded",
      severity: "info",
    };
  else if (report) {
    batch_status = {
      text:
        {
          error: "Publishing failed",
          in_progress: `Publishing ${report.progress}%`,
          sent_to_reprocessing: "Reprocessing requested",
          finished_ok: "Publishing finished",
        }[report.status] || report.status,

      severity:
        {
          error: "error",
          in_progress: "info",
          sent_to_reprocessing: "info",
          finished_ok: "success",
        }[report.status] || "info",
    };
  } else {
    batch_status = {
      text: "Pending",
      severity: "warning",
    };
  }

  const batchClassName = studioProcessing
    ? "publishing-batch studio-processing"
    : "publishing-batch";

  return (
    <div className={batchClassName}>
      <div className="toolbar">
        <Dropdown
          style={{ minWidth: "200px" }}
          placeholder="Select project..."
          tooltip="Project"
          tooltipOptions={{ position: "bottom" }}
          options={projects}
          optionValue="id"
          optionLabel="name"
          value={project}
          onChange={(e) => onSelectProject(e.value)}
          disabled={locked || batchTasks.length > 0}
        />

        <InputText
          style={{ width: "75px " }}
          placeholder="Variant"
          tooltip="Variant"
          tooltipOptions={{ position: "bottom" }}
          value={props.variant}
          onChange={(e) => {
            dispatch({
              type: "SET_BATCH_VARIANT",
              uuid: props.uuid,
              variant: e.target.value,
            });
          }}
          disabled={locked}
        />

        <Button
          label={props.context ? props.context.path : "Select context"}
          tooltipOptions={{ position: "bottom" }}
          tooltip="Context"
          className={
            props.contex
              ? "p-button-text p-button-success"
              : "p-button p-button-warning"
          }
          onClick={() => setShowContextDialog(true)}
          disabled={locked || !project}
        />


        <Button
          icon="pi pi-copy"
          label="Clone"
          className="p-button p-button-success"
          tooltipOptions={{ position: "bottom" }}
          onClick={() => {
            dispatch({ 
              type: "CLONE_BATCH",
              project: props.project,
              context: props.context,
              variant: props.variant,
              studioProcessing: props.studioProcessing,
            });
            setTimeout(() => {
              window.scrollTo(0, document.body.scrollHeight + 1000);
            }, 50);
          }}
        />

        <Spacer />

        <InputSwitch
          tooltipOptions={{ position: "bottom" }}
          tooltip="Enable PSD farm processing"
          checked={studioProcessing}
          disabled={locked || batchTasks.length > 0}
          onChange={(e) =>
            dispatch({
              type: "SET_STUDIO_PROCESSING",
              uuid: props.uuid,
              value: e.value,
            })
          }
        />
        <label>PSD and TVP farm processing {studioProcessing}</label>
        <Spacer />

        <Button
          icon="pi pi-times"
          tooltip="Remove batch"
          tooltipOptions={{ position: "bottom" }}
          onClick={onDeleteBatch}
          className="p-button-text p-button-danger"
          disabled={locked}
        />
      </div>

      <div className="task-list">
        {batchTasks.length ? (
          batchTasks.map((task) => (
            <PublishingBatchTask key={task.uuid} disabled={locked} {...task} />
          ))
        ) : (
          <NoTaskLabel />
        )}
      </div>

      <div className="toolbar">
        {projectSettings && (
          <>
            <FileSelectWidget
              onSelect={onSelectFiles}
              extensions={
                studioProcessing
                  ? projectSettings.studio_exts
                  : projectSettings.file_exts
              }
              label="Add&nbsp;file"
              icon="pi pi-file"
              disabled={locked}
            />

            <FileSelectWidget
              multiple={true}
              extensions={projectSettings.sequence_exts}
              onSelect={onSelectFiles}
              label="Add&nbsp;sequence"
              icon="pi pi-clone"
              disabled={locked || studioProcessing}
            />
          </>
        )}

        <Spacer />



        <Message {...batch_status} />

        {batch_status.severity === "error" && (
          <>
            <Button
              label="Details"
              className="p-button p-button-info"
              onClick={() => props.onShowReportDialog(props.uuid)}
            />

            <Button
              label="Reprocess"
              className="p-button p-button-info"
              onClick={() => onReprocess(props.uuid)}
            />
          </>
        )}
      </div>

      {showContextDialog && (
        <ContextDialog
          project={project}
          currentContext={props.context || {}}
          onHide={() => setShowContextDialog(false)}
          onSelect={(context) => {
            dispatch({
              type: "SET_BATCH_CONTEXT",
              uuid: props.uuid,
              context: context,
            });
            setShowContextDialog(false);
          }}
        />
      )}
    </div>
  );
};

export { PublishingBatch };
//
