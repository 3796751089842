const charsToEncode = /[\u007f-\uffff]/g;

const unicode2ascii = (str) => {
  return str.replace(charsToEncode, (c) => {
    return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
  });
};

const encodeSafeJSON = (v) => {
  return unicode2ascii(JSON.stringify(v));
};

const formatFileSize = (bytes) => {
  if (bytes >= 1000000000) return (bytes / 1000000000).toFixed(2) + " GB";
  if (bytes >= 1048576) return (bytes / 1048576).toFixed(2) + " MB";
  if (bytes >= 1024) return (bytes / 1024).toFixed(2) + " KB";
  return bytes + " bytes";
};

const parseImageSequence = (files, validExts) => {
  // Accept a list of files or filenames and return false if it is
  // not a valid image sequence.
  // Return a base name with a sequence number replaced with # characters.

  const re = /(.+[a-zA-Z.\-_])(\d{3,})\.([a-zA-Z0-9]{3,5})/;

  let lastNumber = -1;
  let lastPrefix = "";
  let lastSuffix = "";
  let lastNumberLength = 0;

  var file_list = files.sort((a, b) => (a.name > b.name ? 1 : -1));

  for (const file of file_list) {
    const filename = file.name;
    let sequence = filename.match(re);
    if (!sequence) return false;
    let prefix = sequence[1];
    let number = parseInt(sequence[2]);
    let suffix = sequence[3];
    let numberLength = sequence[2].length;

    if (validExts.indexOf(suffix) < 0) {
      console.log("parseImageSequence: Invalid file extension: " + suffix);
      return false;
    }

    if (lastNumber === -1) {
      lastNumber = number;
      lastPrefix = prefix;
      lastSuffix = suffix;
      lastNumberLength = numberLength;
      continue;
    }

    if (lastNumber + 1 !== number) {
      console.log("parseImageSequence: Non-monotonous number");
      return false;
    }

    if (prefix !== lastPrefix) {
      console.log("parseImageSequence: Prefix does not match");
      return false;
    }

    if (suffix !== lastSuffix) {
      console.log("parseImageSequence: Suffix does not match");
      return false;
    }

    lastNumberLength = numberLength;
    lastNumber = number;
    lastPrefix = prefix;
    lastSuffix = suffix;
  }
  return lastPrefix + "#######".slice(-lastNumberLength) + "." + lastSuffix;
};

export { unicode2ascii, formatFileSize, encodeSafeJSON, parseImageSequence };
