import { combineReducers } from "redux";
import { v4 as uuidv4 } from "uuid";

const batchesReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case "ADD_BATCH":
      return {
        items: [
          ...state.items,
          {
            uuid: uuidv4(),
            context: null,
            variant: "Main",
          },
        ],
      };

    case "CLONE_BATCH":
      return {
        items: [
          ...state.items,
          {
            uuid: uuidv4(),
            project: action.project,
            context: action.context,
            variant: action.variant,
            studioProcessing: action.studioProcessing,
          },
        ],
      };


    case "SET_BATCH_PROJECT": {
      let result = [];
      for (const batch of state.items) {
        if (batch.uuid === action.uuid) {
          if (action.project !== batch.project) {
            batch.project = action.project;
            batch.context = null;
          }
        }
        result.push(batch);
      }
      return { items: result };
    }

    case "SET_BATCH_VARIANT": {
      let result = [];
      for (const batch of state.items) {
        if (batch.uuid === action.uuid) {
          if (action.variant !== batch.variant) {
            batch.variant = action.variant;
          }
        }
        result.push(batch);
      }
      return { items: result };
    }

    case "SET_BATCH_CONTEXT": {
      let result = [];
      for (const batch of state.items) {
        if (batch.uuid === action.uuid) {
          batch.context = action.context;
        }
        result.push(batch);
      }
      return { items: result };
    }

    case "SET_STUDIO_PROCESSING": {
      let result = [];
      for (const batch of state.items) {
        if (batch.uuid === action.uuid) {
          batch.studioProcessing = action.value;
        }
        result.push(batch);
      }
      return { items: result };
    }

    case "DELETE_BATCH":
      return {
        items: state.items.filter((batch) => batch.uuid !== action.uuid),
      };

    default:
      return state;
  }
};

const tasksReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case "ADD_TASK":
      return {
        items: [
          ...state.items,
          {
            uuid: uuidv4(),
            batch: action.batch,
            files: action.files,
            name: action.name,
            state: "PENDING",
          },
        ],
      };

    case "SET_TASK_STATE": {
      let result = [];
      for (const task of state.items) {
        if (task.uuid === action.uuid) {
          task.state = action.state;
        }
        result.push(task);
      }
      return { items: result };
    }

    case "SET_TASK_PROJECT": {
      let result = [];
      for (const task of state.items) {
        if (task.batch_uuid === action.batch_uuid) {
          task.project = action.project;
        } else if (task.uuid === action.uuid) {
          task.project = action.project;
        }
        result.push(task);
      }
      return { items: result };
    }

    case "SET_FILE_PUBLISHED": {
      let result = [];
      for (const task of state.items) {
        if (task.uuid === action.uuid) {
          task.files[action.index].published = true;
        }
        result.push(task);
      }
      return { items: result };
    }

    case "DELETE_TASK":
      // Delete a single task based on its uuid
      return { items: state.items.filter((task) => task.uuid !== action.uuid) };

    case "DELETE_TASKS":
      // Delete all tasks from a batch based on the batch uuid
      return {
        items: state.items.filter((task) => task.batch !== action.batch),
      };

    default:
      return state;
  }
};

const reducer = combineReducers({ batchesReducer, tasksReducer });
export { reducer };
