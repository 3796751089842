import { useState, useEffect } from "react";
import { Button, Dialog, Message, Spacer } from "../components";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

const ReportItem = ({ item, onSetBatchId }) => {
  const batch_status = {
    text:
      {
        error: "Publishing failed",
        in_progress: `Publishing ${item.progress}%`,
        finished_ok: "Publishing finished",
      }[item.status] || item.status,

    severity:
      {
        error: "error",
        in_progress: "info",
        finished_ok: "success",
      }[item.status] || "info",
  };

  var startTime = moment(item.start_date);

  return (
    <div className="report-item">
      <span style={{ flexBasis: "200px", whiteSpace: "nowrap" }}>
        {startTime.calendar()}
      </span>
      {item.path && (
        <span
          style={{
            backgroundColor: "var(--color-primary)",
            padding: "8px 12px",
            borderRadius: "3px",
            marginLeft: "4px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.path}
        </span>
      )}
      <Spacer />
      <Message {...batch_status} style={{ whiteSpace: "nowrap" }} />
      {item.status === "error" && (
        <Button
          label="Details"
          className="p-button p-button-info"
          onClick={() => onSetBatchId(item.batch_id)}
        />
      )}
    </div>
  );
};

const ReportList = ({ onSetBatchId, userData }) => {
  const [reportData, setReportData] = useState([]);

  const loadUserReport = () => {
    axios.get("/api/report").then((response) => {
      setReportData(response.data.report);
    });
  };

  useEffect(() => {
    loadUserReport();
  }, []);

  return (
    <Dialog
      visible={true}
      header={`Batches by ${userData.name}`}
      style={{
        width: "80%",
      }}
      onHide={() => onSetBatchId(false)}
    >
      <div className="report-list">
        {reportData.map((batch) => (
          <ReportItem
            key={batch._id}
            item={batch}
            onSetBatchId={onSetBatchId}
          />
        ))}
      </div>
    </Dialog>
  );
};

const BatchReport = ({ batchId, onSetBatchId }) => {
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    axios
      .get(`/api/report/${batchId}/full`)
      .then((response) => {
        if (response.data.response === 200) {
          setReportData(response.data.report);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }, [batchId]);

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(reportData.log);
      toast.info("Error log copied to the clipboard");
    } catch (error) {
      toast.error("Unable to access the clipboard. Is HTTPS enabled?");
    }
  };

  const footer = (
    <>
      <Button
        icon="pi pi-angle-double-left"
        label="Publish history"
        className="p-button p-button-info"
        onClick={() => onSetBatchId(true)}
      />
      <Button
        icon="pi pi-copy"
        label="Copy to clipboard"
        className="p-button p-button-info"
        onClick={copyToClipboard}
      />
    </>
  );

  return (
    <Dialog
      visible={true}
      header={`Batch Report`}
      footer={footer}
      style={{
        width: "80%",
      }}
      onHide={() => onSetBatchId(false)}
    >
      {reportData.hasOwnProperty("log") ? (
        <>
          <p>Publishing failed.</p>
          <p>
            Please paste the following report into Pype.club support slack
            channel or send it to{" "}
            <a href="mailto:support@pype.club">support@pype.club</a>
          </p>
          <pre
            style={{
              border: "1px solid #ddd",
              padding: "5px",
              width: "100%",
              height: "400px",
              overflow: "auto",
              whiteSpace: "pre-wrap",
            }}
          >
            {reportData.log}
          </pre>
        </>
      ) : (
        <p>Loading report...</p>
      )}
    </Dialog>
  );
};

const ReportDialog = ({ onSetBatchId, batchId, userData }) => {
  if (batchId === true)
    return <ReportList onSetBatchId={onSetBatchId} userData={userData} />;
  else return <BatchReport batchId={batchId} onSetBatchId={onSetBatchId} />;
};

export { ReportDialog };
